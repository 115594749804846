* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: var(--fonts-500)
}

/* Colors variables */
:root {
    --black: #000000;
    --white: #ffffff;
    --bggray: #F6F6F8;
    --box-border: #e8e8e8;
    --forminput-border: #DDDDE3;
    --form-label: #6E7280;
    --primary: #CBDB2D;
}

.bg-black {
    background-color: var(--black);
}

/* Fonts variables  */
:root {
    --fonts-100: 'Museo Sans 100';
    --fonts-300: 'Museo Sans 300';
    --fonts-500: 'Museo Sans 500';
    --fonts-700: 'Museo Sans 700';
    --fonts-900: 'Museo Sans 900';
}

h1 {
    font-size: 50px;
}

h2 {
    font-size: 40px;
}

h3 {
    font-size: 32px;
}

h4 {
    font-size: 26px;
}

h5 {
    font-size: 20px;
}

h6 {
    font-size: 18px;
}

p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
}

a {
    text-decoration: none;
    color: #000000;
}

/* Form input css  */
.form-control,
.form-select {
    padding: 14px 16px;
    background: var(--white);
    border: 2px solid var(--forminput-border);
    border-radius: 8px;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-control:focus,
.form-select:focus {
    border-color: #000000;
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 51%) !important;
}

.form-check-input:checked {
    background-color: #000000;
    border-color: #000000;
}

.form-check-input:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 25%);
}

.form-tabs .Form .form-label {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #6E7280;
    margin: 0 0 5px 0;
}

.formeye .input-group-text {
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0px, -50%);
    background: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

.btnblack,
.btn-primary {
    background: var(--black) !important;
    width: 100%;
    border: 1px solid var(--black);
    padding: 12px;
    border-radius: 8px;
    height: auto;
}

.btnblack:focus,
.btn-primary:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 34%) !important;
}

.btnblack:hover,
.btn-primary:hover {
    background: transparent !important;
    color: var(--black);
    border: 1px solid var(--black) !important;
    outline: none !important;
}

.btn-primary{
    width: auto;
}


.btn-secondary {
    background: var(--bggray) !important;
    color: var(--black);
    border: 1px solid var(--black) !important;
    border: 1px solid var(--bggray);
    padding: 12px;
    border-radius: 8px;
    height: auto;
}

.btn-secondary:focus {
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 34%) !important;
}

.btn-secondary:hover {
    background: var(--black) !important;
    color: var(--white);
}

table td svg {
    margin: 0 5px;
    cursor: pointer;
}
.svg:first-child{
    background: #43af50;
}

/* Header css  */
.mainheader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}

.mainheader nav.navbar {
    background: var(--white);
    padding: 0;
}

.mainheader nav.navbar .navbar-brand {
    background: var(--black);
    padding: 20px 80px;
}

.main-wraper {
    background: var(--bggray);
    padding: 130px 0 50px 0;
}

.main-wraper .body-wraper {
    background: var(--white);
    border: 0.5px solid var(--box-border);
    box-shadow: 8px 9px 31px rgba(84, 114, 247, 0.09);
    border-radius: 30px;
    max-width: 563px;
    margin: 0 auto;
    padding: 35px;
}

.main-wraper .body-wraper .logo {
    text-align: center;
    margin-bottom: 30px;
}

.main-wraper .body-wraper .form-tabs .nav-tabs {
    margin-bottom: 28px;
    border: none;
    justify-content: center;
}

.main-wraper .body-wraper .form-tabs .nav-tabs li.nav-item {
    flex: 1 1 0;
    justify-content: space-between;
}

.main-wraper .body-wraper .form-tabs .nav-tabs li.nav-item .nav-link {
    width: 100%;
    background: var(--white);
    border: 2px solid var(--black);
    border-radius: 0;
    padding: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--black);
}

.main-wraper .body-wraper .form-tabs .nav-tabs li.nav-item .nav-link.active {
    background: var(--black);
    color: var(--white);
}

.main-wraper .body-wraper .form-tabs .nav-tabs li.nav-item:first-child .nav-link {
    border-radius: 8px 0 0 8px;
}

.main-wraper .body-wraper .form-tabs .nav-tabs li.nav-item:last-child .nav-link {
    border-radius: 0px 8px 8px 0;
}

.title-bar h2 {
    font-family: var(--fonts-700)
}

.dashboard-wrapper {
    padding-top: 84px;
    padding-left: 311px;
    position: relative;
    min-height: 100vh;
}

.dashboard-wrapper .dashboardsidebar {
    width: 311px;
    height: 100vh;
    margin-top: 84px;
    position: fixed;
    transition: all 0.4s ease-in;
    background-color: var(--black);
    top: 0;
    left: 0;
}

.accordion-button:focus {
    box-shadow: none;
    outline: none;
}

.dashboard-block {
    background: var(--bggray);
}

.sidebar-panel {
    height: calc(100vh - 85px);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar-panel a.logoutbtn {
    padding: 18px 25px;
    background: var(--black);
    color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sidebar-panel a.logoutbtn svg {
    margin-right: 10px;
}

.dashboard-wrapper .main-body {
    padding: 40px;
    margin: 40px;
    max-width: 100%;
    min-height: calc(100vh - 180px);
}

.dashboard-wrapper .dashboardsidebar .sidebar-innner {
    margin-top: 1px;
    background: var(--black);
    height: 100vh;
}

.dashboard-wrapper .dashboardsidebar .sidebar-innner .accordion {
    background: var(--black);
}

.dashboard-wrapper .dashboardsidebar .accordion .accordion-item h2.accordion-header button.accordion-button,
.dashboard-wrapper .dashboardsidebar .sidebar-innner .accordion a {
    color: var(--white);
    background: var(--black);
    padding: 18px 25px 18px 25px;
    border-bottom: 1px solid rgba(80, 80, 80, 0.5);
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: var(--white);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dashboard-wrapper .dashboardsidebar .accordion .accordion-item .accordion-collapse {
    background: var(--black);
    color: var(--white);
}

.dashboard-wrapper .dashboardsidebar .accordion .accordion-item h2.accordion-header button.accordion-button::after {
    filter: invert(1);
    opacity: 1;
}

.dashboard-wrapper .dashboardsidebar .accordion .accordion-item .accordion-collapse .accordion-body ul li:last-child a {
    border: none;
}

.dashboard-wrapper .dashboardsidebar .accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 10px 25px 10px 25px;
}

.dashboard-wrapper .dashboardsidebar .accordion .accordion-item {
    background-color: var(--white);
    border: none;
}

.dashboard-wrapper .dashboardsidebar .accordion .accordion-item .accordion-collapse .accordion-body ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dashboard-wrapper .dashboardsidebar .accordion .accordion-item .accordion-collapse .accordion-body ul li a {
    display: block;
    padding: 14px 0;
    color: var(--white);
    text-decoration: none;
    border-bottom: 1px solid rgba(80, 80, 80, 0.5);
    font-size: 16px;
    line-height: 18px;
    color: var(--white);
    font-family: var(--fonts-700);
}

.dashboard-block .dashboard-wrapper .dashboardsidebar li a {
    padding: 18px 25px 18px 25px;
    font-style: normal;
    font-size: 22px;
    line-height: 26px;
    color: var(--white);
    text-decoration: none;
    display: block;
}

.main-body .table-bar table {
    color: var(--black);
}

.main-body .table-bar table thead {
    background: var(--bggray);
    border: none;
}

.table-bar thead tr th {
    background: var(--bggray);
    color: var(--black);
    box-shadow: none;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
    margin: 0;
    padding: 13px;
}

.main-body .table-bar table tbody tr {
    background: var(--white);
    border: none !important;
}

.main-body .table-bar table tbody tr td {
    background: var(--white) !important;
    border: none !important;
    box-shadow: none;
}

.main-body .body-main {
    background: var(--white);
    border-radius: 20px;
}

.main-body {
    background: var(--white);
    border-radius: 20px;
}

.body-main {
    background: var(--bggray);
}

.main-body .body-main .title-bar {
    margin-bottom: 40px;
}

.uploadbar {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-start;
}

.uploadbar label.form-label {
    margin: 0;
    flex: 0 0 160px;
}
.bttnnn{
    margin-top: 20px !important;
}

.btnupload button{
    width: 120px;
    font-size: 14px;
}
.form-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--form-label);
}
.emaillisting ul{
    width: 100%;
}
.btnblac_2 button{
height: 30px;
padding: 2px 10px;
}
.table-bar table thead tr th:last-child{
        text-align: center;
}

.dashboard-wrapper .dashboardsidebar .accordion .accordion-item .accordion-collapse .accordion-body ul li a.active ,
.dashboard-wrapper .dashboardsidebar .accordion  a.active{
    color: var(--primary);
}

.uploadbar input.form-control {
    border: none;
}

.uploadbar input.form-control {
    border: none;
}

.btngroups {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


.forminputs-bar .form-check {
    margin-right: 20px;
}

.forminputs-bar {
    margin: 40px 0 20px 0;
}

.uploademails .input-group-text {
    position: absolute;
    top: 50%;
    transform: translate(0px, -50%);
    right: 10px;
    
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: var(--black);
    padding: 6px 30px;
    cursor: pointer;
    border-radius: 5px !important;
    margin: 0 !important;
}
.uploademails{
    border: 1px solid rgba(128, 128, 128, 0.438);
    padding: 15px;
    border-radius: 12px;
}

.uploademails input.form-control {
    padding-right: 130px;
}

.form .btngroups {
    margin-top: 100px;
}

.form .btngroups {
    margin-top: 100px;
}

.emaillisting li {
    border-bottom: 1px solid #E9ECEE;
}

.emaillisting li .del-btn {
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
}

.emailadded-bar {
    height: 100%;
    background: var(--white);
    border: 1px solid var(--box-border);
    border-radius: 8px;
    padding: 24px;
}

.emailadded-bar .title-bar {
    margin-bottom: 30px;
}

.sidebar-panel a.logoutbtn svg {
    margin-right: 10px;
}

.title-bar .btnblack {
    width: auto;
    padding: 12px 20px;
}

.title-bar .btnblack svg {
    margin-right: 10px;
}

.dropdown-flexing {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dropdown-flexing .dropdown-listing-bar {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.dropdown-flexing .dropdown-listing-bar li {
    margin-left: 10px;
}

.dropdown-flexing .dropdown-listing-bar li .dropdown .custom-dropbar {
    background: transparent !important;
    border: 1px solid var(--black);
    color: var(--black);
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    outline: none !important;
    box-shadow: none !important;
}

.dropdown-listing-bar .dropdown-menu select.form-select,
.dropdown-listing-bar .dropdown-menu .form-control {
    padding: 6px 12px;
    font-size: 12px;
    margin-bottom: 9px;
}

.dropdown-flexing .dropdown-listing-bar li .dropdown .dropdown-menu {
    padding: 0;
}

.dropdown-flexing .dropdown-listing-bar li .dropdown .dropdown-menu .dropdown-item {
    padding: 9px 12px;
    font-size: 12px;
    line-height: 14px;
}

.dropdown-listing-bar .icondropdown {
    border: none !important;
    padding: 0;
    font-size: 30px !important;
}

.dropdown-listing-bar .icondropdown::after {
    display: none;
}

.dropdown-listing-bar .dropdown-menu {
    padding: 12px !important;
    width: 250px !important;
}

.dropdown-listing-bar .dropdown-menu h6 {
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: var(--form-label);
    font-family: 'Museo Sans 700';
}

.dropdown-listing-bar .dropdown-menu ul {
    padding: 0;
    list-style: none;
}

.dropdown-listing-bar .dropdown-menu ul li {
    margin: 0;
}

.dropdown-listing-bar .dropdown-menu ul li button {
    padding: 2px 2px;
    border: none;
    background: transparent;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: var(--black);
}

.dropdown-flexing .dropdown-listing-bar li .dropdown .dropdown-menu button.btnblack {
    width: 100%;
    color: #ffffff;
}

.contactorlist {
    padding: 20px;
    border: 1px solid var(--box-border);
    margin: 16px;
}

.contactorlist ul.contactorlist-ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.contactorlist ul.contactorlist-ul li {
    flex: 0 0 50%;
    font-size: 20px;
}

.contactorlist ul.contactorlist-ul {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--box-border);
}

#mainLoaderElement {
    position: fixed;
    background: rgba(255,255,255,1);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .maxImageView{
    width: auto;
    height: 300px;
  }

  .main-body .table-bar table tbody tr td{
    vertical-align: middle;
  }

  .main-body .table-bar table tbody tr td,
  .main-body .table-bar table thead tr th{
    text-align: left !important;
  }

  .alert-warning h4 {
    margin: 0;
    font-size: 19px;
}

.contactorlist-parent-box{
    padding: 0;
    border: none;
}

.custom-warn-box {
    text-align: center;
    display: flex;
    align-items: center;
    padding: 15px;
}

.custom-warn-box  button{
    margin-left: auto;
    margin-right: 0;
}
.btnupload {
    text-align: center;
}
table tbody tr td:first-child{
    padding-left: 20px;
}

/* ----------------------- responsiveness ----------------- */

@media only screen and (max-width:1199px){
    .dashboard-wrapper {
    padding-left: 0;
}
.dropdown-flexing .dropdown-listing-bar li {
    margin-left: 10px;
}
.dashboard-wrapper .main-body {
    padding: 40px;
    margin: 40px;
    max-width: 100%;
    min-height: calc(100vh - 180px);
}

.dashboard-wrapper .dashboardsidebar {
    left: -100%;
    z-index: 9999;
}
.buttontoggle .dashboardsidebar{
    left:0;
}
.emailadded-bar{
    margin-top: 30px;
}
.dropdown-flexing .dropdown-listing-bar {
    list-style: none;
    display: flex;
    align-items: unset;
    flex-direction: column;
    padding: 0 !important;
}
.dropdown-flexing {   
    display: flex;
    flex-direction: column;
    align-items: unset;
}
.dropdown-flexing li{
    padding: 10px;
}
.main-body .body-main .title-bar{
    position: relative;
}
.setting_button{
    position: absolute;
    top: 0;
    right: 0;
}
}


@media only screen and (max-width:574px){
    .mainheader nav.navbar .navbar-brand {
        padding: 20px 35px;
    }
    .uploadbar {
        display: block;
    }
    .main-body .body-main .title-bar {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
    }
    .dropdown-flexing .dropdown-listing-bar {
        list-style: none;
        display: flex;
        align-items: unset;
        flex-direction: column;
        padding: 0 !important;
    }
    .dashboard-wrapper .dashboardsidebar{
        width: 220px;
    }
    .dropdown-flexing {
       
        display: flex;
        flex-direction: column;
        align-items: unset;
    }
    .dropdown-flexing li{
        padding: 10px;
    }
    .form-tabs ul li{
        margin: 10px 0;
    }
    .form-tabs ul{
        flex-direction: column;
    }
    .dashboard-wrapper .main-body {
        padding: 28px;
        margin: 24px 15px;
        max-width: 100%;
        min-height: calc(100vh - 180px);
    }
    .title-bar .btnblack {
        width: auto;
        padding: 7px 6px;
    }
    .title-bar h2{
        font-size: 30px;
    }
    
}
